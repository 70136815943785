<template>
  <v-card class="ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width" color="light_gray" tile elevation="0">
    <v-row align="center" no-gutters>
      <v-col cols="12" md="12" lg="10">
        <v-form ref="profileForm">
          <v-row>
            <v-col cols="6">
              <span class="gray--text font-weight-bold text-body-1 text-capitalize">{{ $t(`label.perosnalDetials`) }}</span>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn v-if="!isProfileInEditMode" text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="pa-0" @click="isProfileInEditMode = !isProfileInEditMode">
                <span class="unbox_primary--text text-capitalize font-weight-bold text-body-1 cursor-pointer">{{ $t(`label.edit`) }}</span>
              </v-btn>
              <div v-else class="d-inline-block">
                <v-btn text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="mr-4 pa-0" @click="submitProfileInformation">
                  <span class="unbox_primary--text text-capitalize font-weight-bold text-body-1 cursor-pointer">{{ $t(`label.update`) }}</span>
                </v-btn>

                <v-btn text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="pa-0" @click="resetCustomerProfileInformation">
                  <span class="unbox_black--text text-capitalize text-body-1 cursor-pointer">{{ $t(`label.cancel`) }}</span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <app-form-field
                v-model.trim="customerInfo.firstName"
                :readonly="!isProfileInEditMode"
                :label="$t(`label.firstName`)"
                :placeholder="$t(`label.firstName`)"
                :rules="$validation.nameRules()"
              ></app-form-field>
            </v-col>
            <v-col cols="12" lg="6">
              <app-form-field
                v-model.trim="customerInfo.lastName"
                :readonly="!isProfileInEditMode"
                :label="$t(`label.lastName`)"
                :placeholder="$t(`label.lastName`)"
                :rules="$validation.nameRules()"
              ></app-form-field>
            </v-col>
            <v-col cols="12" lg="6">
              <app-form-field
                mask="######-##-####"
                :readonly="!isProfileInEditMode"
                v-model.trim="customerInfo.idNumber"
                :label="$t(`label.identityCardNo`)"
                :placeholder="$t(`label.identityCardNo`)"
                :rules="$validation.malaysianIdentityNumberRules()"
              ></app-form-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-menu v-model="birthdayDatePickerMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-body-2 font-weight-bold unbox_secondary--text mb-2">{{ $t(`label.dateOfBirth`) }}</div>
                  <v-text-field
                    class="rounded-lg"
                    v-model="customerInfo.birthday"
                    :placeholder="$t(`label.dateOfBirth`)"
                    readonly
                    :disabled="!isProfileInEditMode"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    background-color="white"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="customerInfo.birthday" @input="birthdayDatePickerMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
              <v-radio-group v-model.trim="customerInfo.gender" row class="mt-0" :readonly="!isProfileInEditMode" :disabled="!isProfileInEditMode">
                <v-radio color="#6EE7B7" v-for="item in $ddl.getGenders()" :key="item.value" :label="item.text" :value="item.value"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" lg="6">
              <span class="gray--text font-weight-bold text-body-1">{{ $t(`label.contactDetails`) }}</span>
            </v-col>
            <v-col cols="12" lg="6" class="text-right"></v-col>
            <v-col cols="12" lg="6">
              <app-form-field v-model.trim="customerInfo.email" readonly :label="$t(`label.email`)" :placeholder="$t(`label.email`)" :rules="$validation.emailRules()"></app-form-field>
            </v-col>
            <v-col cols="12" lg="6">
              <app-form-field
                :readonly="!isProfileInEditMode"
                :prefix="customerInfo.phoneCode"
                v-model.trim="customerInfo.phone"
                :label="$t(`label.mobileNumber`)"
                :placeholder="$t(`label.mobileNumber`)"
                :rules="$validation.mobileNumberRules()"
              ></app-form-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { localeHelper, sharedHelper } from '@/utils'
import { CUSTOMER_UPDATE_INFORMATION } from '@/store/customer.module'
import { SESSION } from '@/constants'
export default {
  name: 'account',
  data: () => ({
    isProfileInEditMode: false,
    birthdayDatePickerMenu: false,
    customerInfoOri: {}
  }),
  computed: {
    customerInfo() {
      return this.$store.state.customer.info
    },
    updateInformationResponseComplete() {
      return this.$store.state.customer.updateInformationResponse.complete
    }
  },
  created() {},
  watch: {
    'customerInfo.idNumber'(newVal) {
      if (newVal) {
        let birthday = sharedHelper.getBirthdayDateFromIdentityNumber(newVal)
        if (birthday != undefined) {
          this.customerInfo.birthday = birthday
          let lastDigit = parseInt(newVal.slice(-1))

          if (lastDigit) {
            this.customerInfo.gender = lastDigit % 2
          }
        }
      }
    },
    customerInfo() {
      this.customerInfoOri = JSON.parse(JSON.stringify(this.$store.state.customer.info))
    },
    updateInformationResponseComplete() {
      let response = this.$store.state.customer.updateInformationResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.updateProfileComplete`))
          this.customerInfoOri = JSON.parse(JSON.stringify(this.$store.state.customer.info))
          this.resetCustomerProfileInformation()
        }
      }
    }
  },
  methods: {
    resetCustomerProfileInformation() {
      this.customerInfo.firstName = this.customerInfoOri.firstName
      this.customerInfo.lastName = this.customerInfoOri.lastName
      this.customerInfo.idNumber = this.customerInfoOri.idNumber
      this.customerInfo.birthday = this.customerInfoOri.birthday
      this.customerInfo.gender = this.customerInfoOri.gender
      this.customerInfo.phone = this.customerInfoOri.phone
      this.isProfileInEditMode = false
      this.$refs.profileForm.resetValidation()
    },
    submitProfileInformation() {
      if (this.$refs.profileForm.validate()) {
        let data = {
          uuid: localStorage.getItem(SESSION.CUSTOMER_UUID),
          firstName: this.customerInfo.firstName,
          lastName: this.customerInfo.lastName,
          idNumber: this.customerInfo.idNumber,
          birthday: this.customerInfo.birthday,
          gender: this.customerInfo.gender,
          phone: this.customerInfo.phone,
          phoneCode: this.customerInfoOri.phoneCode
        }
        this.$store.dispatch(CUSTOMER_UPDATE_INFORMATION, { data })
      }
    }
  }
}
</script>
